"use client"

import { useFormStatus } from "react-dom"
import { Button, type ButtonProps } from "@/components/ui/button"

interface SubmitButtonProps extends Omit<ButtonProps, "asChild" | "href"> {
    label?: string
    pendingLabel?: string
    disabled?: boolean
    children?: React.ReactNode
}

export function SubmitButton({
    label = "Submit",
    pendingLabel = "Submitting...",
    disabled = false,
    size = "lg",
    children,
    ...buttonProps
}: SubmitButtonProps) {
    const { pending } = useFormStatus()
    const displayLabel = pending ? pendingLabel : label

    return (
        <Button
            {...buttonProps}
            size={size}
            type="submit"
            aria-disabled={pending}
            disabled={pending || disabled}>
            <span className="flex items-center gap-2">
                {displayLabel}
                {children}
            </span>
        </Button>
    )
}

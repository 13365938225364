import { z } from "zod"
import isEmail from "validator/lib/isEmail"

/**
 *
 *  Define a Zod schema for email validation using validator.js which supports RFC 6530
 *  and RFC 6531
 *
 *  This is to support i18n email adresses like "desny.brügemann@dpgmedia.nl" (patient zero)
 *
 *  We should use this schema everywhere where we need to validate emails.
 *
 */

export const i18nEmailSchema = (errorMessage?: string) =>
    z.string().refine(
        (email) =>
            isEmail(email, {
                allow_utf8_local_part: true,
                allow_ip_domain: true,
                require_tld: true
            }),
        errorMessage || "Invalid email format"
    )

export type I18nEmail = z.infer<ReturnType<typeof i18nEmailSchema>>

/**
 * Usage examples:
 *
 * 1. Basic usage with default error message:
 * ```typescript
 * const emailSchema = i18nEmailSchema()
 *
 * // In a Zod schema
 * const formSchema = z.object({
 *   email: i18nEmailSchema()
 * })
 *
 * // Validation
 * try {
 *   emailSchema.parse("user@example.com") // Valid
 *   emailSchema.parse("invalid-email") // Throws error with message "Invalid email format"
 * } catch (error) {
 *   // Handle validation error
 * }
 * ```
 *
 * 2. With custom error message:
 * ```typescript
 * const emailSchema = i18nEmailSchema("Please enter a valid email address")
 *
 * // In a form schema
 * const formSchema = z.object({
 *   contactEmail: i18nEmailSchema("Contact email must be valid")
 * })
 * ```
 *
 * 3. With React Hook Form:
 * ```typescript
 * import { useForm } from "react-hook-form"
 * import { zodResolver } from "@hookform/resolvers/zod"
 *
 * const formSchema = z.object({
 *   email: i18nEmailSchema("Please provide a valid email")
 * })
 *
 * const { register, handleSubmit } = useForm({
 *   resolver: zodResolver(formSchema)
 * })
 * ```
 */

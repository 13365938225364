"use client"

import { Mail, Phone, AlertCircle } from "lucide-react"
import React, { useRef, useState, useActionState } from "react"
import { SubmitButton } from "./generic-forms/submit-button"
import ConfirmationDialog from "./generic-forms/confirmation-dialog"
import type { FormState } from "./generic-forms/state"
import { emailContactFormAction } from "@/actions/public/email-contact-form.action"
import HCaptcha from "@hcaptcha/react-hcaptcha"
import { env, isDevelopment } from "@/env"
import { contactFormSchema, type ContactFormData } from "@/schemas/contact-form"
import { useDebounce } from "@/hooks/use-debounce"

export const SVGComponent: React.FC = () => (
    <svg
        className="absolute inset-0 h-full w-full stroke-tce-700/50 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
        aria-hidden="true"
    >
        <defs>
            <pattern
                id="54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2"
                width={200}
                height={200}
                x="100%"
                y={-1}
                patternUnits="userSpaceOnUse"
                className="opacity-50"
            >
                <path d="M130 200V.5M.5 .5H200" fill="none" />
            </pattern>
        </defs>
        <svg x="100%" y={-1} className="overflow-visible fill-tce-700/20">
            <path d="M-470.5 0h201v201h-201Z" strokeWidth={0} />
        </svg>
        <rect width="100%" height="100%" strokeWidth={0} fill="url(#54f88622-e7f8-4f1d-aaf9-c2f5e46dd1f2)" />
    </svg>
)

interface LabelProps {
    htmlFor: string
    children: React.ReactNode
    required?: boolean
}

export const Label: React.FC<LabelProps> = ({ htmlFor, children, required }) => (
    <label htmlFor={htmlFor} className="block text-sm font-semibold leading-6 text-white">
        {children}
        {required && <span className="text-red-500 ml-1">*</span>}
    </label>
)

function LeftPanel({ title, description }: { title?: string; description?: string }) {
    const displayTitle = title || "Get in touch"
    const displayDescription = description || "Leave a message and you'll hear back from us shortly."
    return (
        <div className="relative px-6 pb-20 pt-20 sm:pt-32 lg:static lg:px-8 lg:py-24">
            <div className="mx-auto max-w-xl lg:mx-0 lg:max-w-lg">
                {/* <div className="absolute inset-y-0 left-0 -z-10 w-full overflow-hidden ring-1 ring-white/5 lg:w-1/2">
                    <SVGComponent />
                    <div
                        className="absolute -left-56 top-[calc(100%-13rem)] transform-gpu blur-3xl lg:left-[max(-14rem,calc(100%-59rem))] lg:top-[calc(50%-7rem)]"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[1155/678] w-[72.1875rem] bg-gradient-to-br from-[#80caff] to-[#4f46e5] opacity-20"
                            style={{
                                clipPath:
                                    "polygon(74.1% 56.1%, 100% 38.6%, 97.5% 73.3%, 85.5% 100%, 80.7% 98.2%, 72.5% 67.7%, 60.2% 37.8%, 52.4% 32.2%, 47.5% 41.9%, 45.2% 65.8%, 27.5% 23.5%, 0.1% 35.4%, 17.9% 0.1%, 27.6% 23.5%, 76.1% 2.6%, 74.1% 56.1%)"
                            }}
                        />
                    </div>
                </div> */}
                <h2 className="text-4xl font-bold tracking-tight text-white">{displayTitle}</h2>
                <p className="mt-6 text-lg leading-8 text-gray-300">{displayDescription}</p>
                <dl className="mt-10 space-y-4 text-base leading-7 text-gray-300">
                    <div className="flex gap-x-4">
                        <dt className="flex-none">
                            <span className="sr-only">Telephone</span>
                            <Phone className="h-7 w-6 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd>
                            <a className="hover:text-white" href="tel:+31 88 002 4040">
                                +31 88 002 4040
                            </a>
                        </dd>
                    </div>
                    <div className="flex gap-x-4">
                        <dt className="flex-none">
                            <span className="sr-only">Email</span>
                            <Mail className="h-7 w-6 text-gray-400" aria-hidden="true" />
                        </dt>
                        <dd>
                            <a className="hover:text-white" href="mailto:hello@tce.exchange">
                                hello@tce.exchange
                            </a>
                        </dd>
                    </div>
                </dl>
            </div>
        </div>
    )
}

const initialState: FormState = {
    message: "",
    errors: [],
    success: false
}

const ErrorMessage: React.FC<{ id: string; formState: FormState }> = ({ id, formState }) => {
    if (!formState.errors) return null
    const error = formState.errors.find((e) => e.path === id)
    if (!error || !error.message) return null
    return (
        <p className="sm:text-sm pt-1 flex items-center text-tce-400">
            <AlertCircle className="h-7 w-6 text-tce-400 pr-1" aria-hidden="true" />
            {error.message}
        </p>
    )
}

function Form({
    contactTargetCode,
    prefillMessage
}: {
    contactTargetCode?: string
    prefillMessage?: string
}) {
    const [formState, formAction] = useActionState(emailContactFormAction, initialState)
    const [confirmationDialogHidden, setConfirmationDialogHidden] = React.useState(false)
    const [fieldErrors, setFieldErrors] = useState<Partial<Record<keyof ContactFormData, string>>>({})
    const [formData, setFormData] = useState<Partial<ContactFormData>>({})

    const hCaptchaSiteKey = env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY

    const ref = useRef<HTMLFormElement>(null)
    const captchaRef = useRef<HCaptcha | null>(null)
    const [captchaToken, setCaptchaToken] = useState<string | null>(null)

    const onCaptchaChange = (token: string) => setCaptchaToken(token)
    const onCaptchaExpire = () => setCaptchaToken(null)

    const validateField = (field: keyof ContactFormData, value: string) => {
        try {
            const validationResult = contactFormSchema.safeParse({
                [field]: value,
                // Add dummy values for other required fields to allow partial validation
                name: field === "name" ? value : "dummy",
                email: field === "email" ? value : "dummy@example.com"
            })

            if (!validationResult.success) {
                const fieldError = validationResult.error.errors.find((err) => err.path[0] === field)
                if (fieldError) {
                    setFieldErrors((prev) => ({ ...prev, [field]: fieldError.message }))
                } else {
                    setFieldErrors((prev) => ({ ...prev, [field]: undefined }))
                }
            } else {
                setFieldErrors((prev) => ({ ...prev, [field]: undefined }))
            }
        } catch (error) {
            console.error("Validation error:", error)
        }
    }

    const debouncedValidate = useDebounce(validateField, 300)

    const handleInputChange = (field: keyof ContactFormData, value: string) => {
        setFormData((prev) => ({ ...prev, [field]: value }))
        debouncedValidate(field, value)
    }

    const hasErrors = Object.values(fieldErrors).some(Boolean)

    // Add this useEffect to set a dummy token in development
    React.useEffect(() => {
        if (isDevelopment) {
            setCaptchaToken("development_dummy_token")
        }
    }, [])

    return (
        <form
            ref={ref}
            id="contact-form"
            action={formAction}
            className="px-6 pb-20 pt-20 sm:pb-32 lg:px-8 lg:py-24"
            onSubmit={(e) => {
                if (hasErrors) {
                    e.preventDefault()
                }
            }}
        >
            <div className="mx-auto max-w-xl lg:mr-0 lg:max-w-lg text-white">
                <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div>
                        <Label required htmlFor="name">
                            Name
                        </Label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="name"
                                id="name"
                                autoComplete="name"
                                onChange={(e) => handleInputChange("name", e.target.value)}
                                aria-invalid={Boolean(fieldErrors.name)}
                                aria-describedby={fieldErrors.name ? "name-error" : undefined}
                                className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                            />
                        </div>
                        <ErrorMessage
                            id="name"
                            formState={{
                                ...formState,
                                errors: fieldErrors.name ? [{ path: "name", message: fieldErrors.name }] : []
                            }}
                        />
                    </div>

                    <div>
                        <Label required htmlFor="email">
                            Email
                        </Label>
                        <div className="mt-2.5">
                            <input
                                type="email"
                                name="email"
                                id="email"
                                defaultValue={isDevelopment ? "playwright@example.com" : ""}
                                autoComplete="email"
                                onChange={(e) => handleInputChange("email", e.target.value)}
                                aria-invalid={Boolean(fieldErrors.email)}
                                aria-describedby={fieldErrors.email ? "email-error" : undefined}
                                className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                            />
                        </div>
                        <ErrorMessage
                            id="email"
                            formState={{
                                ...formState,
                                errors: fieldErrors.email
                                    ? [{ path: "email", message: fieldErrors.email }]
                                    : []
                            }}
                        />
                    </div>
                    <div className="sm:col-span-2">
                        <Label htmlFor="phone">Phone Number</Label>
                        <div className="mt-2.5">
                            <input
                                type="tel"
                                name="phone"
                                id="phone"
                                autoComplete="tel"
                                onChange={(e) => handleInputChange("phone", e.target.value)}
                                aria-invalid={Boolean(fieldErrors.phone)}
                                aria-describedby={fieldErrors.phone ? "phone-error" : undefined}
                                className="w-[47%] block rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                            />
                        </div>
                        <ErrorMessage
                            id="phone"
                            formState={{
                                ...formState,
                                errors: fieldErrors.phone
                                    ? [{ path: "phone", message: fieldErrors.phone }]
                                    : []
                            }}
                        />
                    </div>
                    <div className="sm:col-span-2">
                        <Label htmlFor="company">Company</Label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                name="company"
                                id="company"
                                autoComplete="organisation"
                                onChange={(e) => handleInputChange("company", e.target.value)}
                                aria-invalid={Boolean(fieldErrors.company)}
                                aria-describedby={fieldErrors.company ? "company-error" : undefined}
                                className="w-full block rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                            />
                        </div>
                        <ErrorMessage
                            id="company"
                            formState={{
                                ...formState,
                                errors: fieldErrors.company
                                    ? [{ path: "company", message: fieldErrors.company }]
                                    : []
                            }}
                        />
                    </div>
                    <div className="sm:col-span-2">
                        <Label htmlFor="message">Message</Label>
                        <div className="mt-2.5">
                            <textarea
                                name="message"
                                id="message"
                                rows={4}
                                onChange={(e) => handleInputChange("message", e.target.value)}
                                aria-invalid={Boolean(fieldErrors.message)}
                                aria-describedby={fieldErrors.message ? "message-error" : undefined}
                                className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-indigo-500 sm:text-sm sm:leading-6"
                                defaultValue={prefillMessage}
                            />
                        </div>
                        <ErrorMessage
                            id="message"
                            formState={{
                                ...formState,
                                errors: fieldErrors.message
                                    ? [{ path: "message", message: fieldErrors.message }]
                                    : []
                            }}
                        />
                    </div>
                    <div className="sm:col-span-2 flex items-center">
                        <input
                            type="checkbox"
                            name="updates"
                            id="updates"
                            className="form-checkbox h-5 w-5 text-indigo-600"
                        />
                        <label htmlFor="updates" className="ml-2">
                            I&apos;d like to receive email updates.
                        </label>
                    </div>
                    {!isDevelopment && (
                        <HCaptcha
                            sitekey={hCaptchaSiteKey!}
                            onVerify={onCaptchaChange}
                            ref={captchaRef}
                            onExpire={onCaptchaExpire}
                            size="normal"
                            theme="dark"
                        />
                    )}
                </div>

                <div className="mt-8 flex justify-end">
                    <input type="hidden" name="contactTargetCode" value={contactTargetCode || "default"} />
                    <input type="hidden" name="captchaToken" value={captchaToken || ""} />
                    <SubmitButton
                        label="Send Message"
                        pendingLabel="Sending Message..."
                        disabled={hasErrors}
                    />
                </div>
                {!formState.success && <p className="pt-2">{formState?.message}</p>}
            </div>
            <ConfirmationDialog
                open={!confirmationDialogHidden && Boolean(formState.success)}
                title="Message was sent"
                message="Your message has been received. We'll be in touch soon."
                onClose={() => {
                    setConfirmationDialogHidden(true)
                    ref.current?.reset()
                    setFieldErrors({})
                    setFormData({})
                    // Reset captcha if needed
                    if (captchaRef.current) {
                        captchaRef.current.resetCaptcha()
                    }
                }}
            />
        </form>
    )
}

export function NewContactForm({
    title,
    description,
    contactTargetCode,
    prefillMessage
}: {
    title?: string
    description?: string
    contactTargetCode?: string
    prefillMessage?: string
}) {
    return (
        <div className="relative isolate bg-tce-800 rounded-xl">
            <div className="mx-auto grid max-w-7xl grid-cols-1 lg:grid-cols-5">
                <div className="lg:col-span-2">
                    <LeftPanel title={title} description={description} />
                </div>
                <div className="lg:col-span-3">
                    <Form contactTargetCode={contactTargetCode} prefillMessage={prefillMessage} />
                </div>
            </div>
        </div>
    )
}

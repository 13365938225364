import { z } from "zod"
import { i18nEmailSchema } from "@/lib/email"

export const contactFormSchema = z.object({
    name: z.string().min(2, "Name is required").max(50, "Name is too long"),
    email: i18nEmailSchema("Email must be valid"),
    phone: z
        .string()
        .optional()
        .refine((val) => !val || val.length >= 10, {
            message: "Phone number should have at least 10 digits if provided."
        })
        .refine((val) => !val || val.length <= 15, {
            message: "Phone number is too long"
        }),
    company: z
        .string()
        .optional()
        .refine((val) => !val || val.length <= 70, {
            message: "Company name is too long"
        }),
    message: z
        .string()
        .optional()
        .refine((val) => !val || val.length <= 400, {
            message: "Message is too long"
        }),
    updates: z
        .string()
        .transform((val) => (val === "on" ? "yes, i want to receive email updates" : val))
        .optional()
        .default("no"),
    contactTargetCode: z.string().optional().default("default"),
    captchaToken: z.string().optional().default("")
})

export type ContactFormData = z.infer<typeof contactFormSchema>
